<template>
  <div class=" mt-2">
    <b-card no-body class="mb-0">

        <template #header>
          <div class=" d-flex justify-content-start" style="align-items: center;">
            <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="d-flex"
            style="align-items:center;"
            @click="back()"

          >
            <feather-icon
              icon="ArrowLeftCircleIcon"
              class="mr-50"
              size="23"
            />
      <span class="align-middle" >{{ $t('general_buttons.back') }}</span>
    </b-button>
    <h4 class="mb-0 ml-2">{{ $t('account_creation.titles.selected_account_name') }} <b >{{ accountName }}</b> {{ $t('account_creation.titles.codes') }}</h4>
          </div>
        
      </template>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <hr />

        <b-table
          striped
          hover
          ref="refSelectedAccountCodesTable"
          class="position-relative p-2"
          :items="allAccountCodes"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          @row-clicked="onRowSelected"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          fixed
          head-variant="light"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('account_creation.messages.no_matching_account_codes_found')"
          :current-page="currentPage"
          :per-page="perPage"
          caption-top
        >
          <template #cell(ID)="data">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.item.id }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountName -->
          <template #cell(AccountName)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountName ? data.item.AccountName : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountNumber -->
          <template #cell(AccountNumber)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountNumber ? data.item.AccountNumber : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountCode -->
          <template #cell(AccountCode)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountCode ? data.item.AccountCode : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountCcy -->
          <template #cell(AccountCcy)="data">
            <span>{{ data.value }}</span>
          </template>

          <!-- Column: AccountType -->
          <template #cell(AccountType)="data">
            <span>{{ data.value }}</span>
          </template>

          <!-- Column: CreatedBy -->
          <template #cell(CreatedBy)="data">
            <span>{{ data.value }}</span>
          </template>
          <!-- Column: CreationDate -->
          <template #cell(CreationDate)="data">
            <span>{{ dateFormat(data.value) }}</span>
          </template>
          <!-- Column: CreationTime -->
          <template #cell(CreationTime)="data">
            <span>{{ data.value }}</span>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            <span class="text-muted"
                >{{$t('general_text.showing')}} {{ dataMeta.from }} {{$t('general_text.to')}} {{ dataMeta.to }} {{$t('general_text.of')}}
                {{ dataMeta.of }} {{$t('general_text.entries')}}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import accountStoreModule from "../accountStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import "cleave.js/dist/addons/cleave-phone.us";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const ACCOUNT_APP_STORE_MODULE_NAME = "account";
    // Register module
    if (!store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME))
      store.registerModule(ACCOUNT_APP_STORE_MODULE_NAME, accountStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME))
        store.unregisterModule(ACCOUNT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      allAccountCodes: [],
      updatePermloading: false,
      AccountModalLoading: false,
      confrimPassword: null,
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
      amendedReasonText: null,
      accountName: null,
      preAccount: null,
      accountData: {
        AccountName: null,
        AccountNumber: null,
        HolderType: "INDIVIDUAL",
        SettlementType: "CUSTODIAN",
        DisplayName: null,
        Phone1: null,
        Phone2: null,
        ContactEmail: null,
        Email2: null,
        Address: null,
        ID: null,
        AmendedBy: null,
        AmendmentDate: null,
        AmendmentReason: null,
        AmendmentTime: null,
        ClientNote: null,
        CreatedBy: null,
        CreationDate: moment().format("YYYY-MM-DD"),
        CreationTime: moment().format("HH:mm:ss"),
        VerificationDate: null,
        VerificationTime: null,
        VerifiedBy: null,
      },
      filterOn: ["AccountName"],
      tableColumns: [
        {
          key: "ID",
          label: "ID",
          class: "text-center w-0 padding_08",
        },
        {
          key: "AccountName",
          label: this.$t('account_creation.code_table.account_name'),
          sortable: true,
          class: "text-center padding_08",
        },
        {
          key: "AccountNumber",
          label: this.$t('account_creation.code_table.account_number'),
          sortable: false,
          class: "text-center padding_08",
        },

        {
          key: "AccountCode",
          label: this.$t('account_creation.code_table.account_code'),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "AccountCcy",
          label: this.$t('account_creation.code_table.account_ccy'),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "AccountType",
          label: this.$t('account_creation.code_table.account_type'),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "CreatedBy",
          label: this.$t('account_creation.code_table.created_by'),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "CreationDate",
          label: this.$t('account_creation.code_table.creation_date'),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "CreationTime",
          label: this.$t('account_creation.code_table.creation_time'),
          sortable: false,
          class: "text-center padding_08",
        },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
      filterDateStart: null,
      filterDateEnd: null,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["authUser"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.accountName = this.$route.query.accountName;
    this.getAllEntrieCodes(this.$route.params.id);
  },
  methods: {
    back() {
      router.go(-1)
    },
    onRowSelected(row) {},
    //ALL DATAS
    getAllEntrieCodes(id) {
      this.loading = true;
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("account/getSelectedAccountCodes", {
              auth: this.user,
              accountId: Number(id),
            })
            .then((res) => {
              this.allAccountCodes = res.data.codes;
              this.totalRows = res.data.codes.length;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text:  this.$t('account_creation.messages.database_connection_error'),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    warningMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t('account_creation.messages.not_authorized'),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    dateFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    },
    clockFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("HH:mm:ss");
      } else {
        return "-";
      }
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
  },
};
</script>

<style>
#accountModal .modal-header .modal-title {
  color: white !important;
}
.padding_08 {
  padding: 0.7rem !important;
}
</style>

<style lang="scss" scoped>
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
